import { proxy } from 'valtio';

import { type ProductReferenceCode, type ProductType } from '@/libs/enum';

export type TaxableAmount = {
  amount: number;
  excludeGstAmount: number;
  gstAmount: number;
};

export const whatsIncluded = [
  'Unlimited digital variations',
  'Unlimited digital claims',
  'Financial security',
  'Priority support',
  'Cashflow funding',
  'Automated admin',
];

export type Product = {
  id: string;
  type: ProductType;
  code: string;
  referenceCode: ProductReferenceCode;
  name: string;
  description: string;
  taxablePrice: TaxableAmount;
};

const initialState = {
  current: null as ProductReferenceCode | null,
  products: [] as Product[],
  loading: false,
  forceUpdate: 0,
};

export const productManager = proxy(initialState);

export const resetProducts = () => {
  Object.assign(productManager, initialState);
};

export const setCurrentProduct = (value?: ProductReferenceCode | null) => {
  productManager.current = value ?? null;
};

export const saveProducts = (value: Product[]) => {
  const { current } = productManager;
  const currentProduct = value.find((v) => v.referenceCode === current);
  productManager.products = value;
  productManager.current = currentProduct ? currentProduct.referenceCode : null;
};

export const reloadProducts = () => {
  const { forceUpdate } = productManager;
  productManager.forceUpdate = forceUpdate > 100 ? 1 : forceUpdate + 1;
};

export const toggleLoading = (payload: boolean) => {
  productManager.loading = payload;
};
