'use client';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import { HelpCircleIcon, LayoutDashboardIcon, MenuIcon } from 'lucide-react';
import { useSnapshot } from 'valtio';

import { Logo } from '@/components/logo';
import { Separator } from '@/components/separator';
import { UnstyledButton } from '@/components/unstyled-button';
import { userManager } from '@/models/user';
import { useSignOut } from '@/mutations/sign-out';

import { NavbarLink } from '../navbar-link';
import { UserAvatar } from '../user-avatar';

export const HamburgerMenu: React.FC = () => {
  const { user } = useSnapshot(userManager);
  const { mutate, isPending } = useSignOut();
  const fullName = [user?.firstName, user?.lastName].filter(Boolean).join(' ');
  const businessName = user?.business?.registeredName;

  const handleSignOut = () => {
    mutate();
  };

  return (
    <DialogPrimitive.Root>
      <DialogPrimitive.Trigger className="inline-flex size-8 shrink-0 cursor-pointer items-center justify-center bg-transparent text-white/60 no-underline transition-colors duration-150 ease-linear hover:text-white focus:text-white/80 sm:hidden">
        <MenuIcon className="size-6" />
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="fixed inset-0 z-[200] bg-black/70 will-change-[opacity]" />
        <DialogPrimitive.Content className="fixed inset-y-0 left-0 right-20 z-[400] bg-black text-white shadow will-change-[opacity,transform] data-[state=open]:animate-slideLeftAndFade landscape:right-[61.8%]">
          <div className="flex size-full flex-col overflow-y-auto overflow-x-hidden p-4">
            <header className="px-5 py-10">
              <Logo height={30} />
            </header>
            <nav className="flex flex-col gap-y-3.5">
              <div>
                <DialogPrimitive.Close asChild>
                  <NavbarLink mobile href="/users" className="py-1">
                    <LayoutDashboardIcon className="size-8 shrink-0 p-1" />
                    <div>Users</div>
                  </NavbarLink>
                </DialogPrimitive.Close>
                <DialogPrimitive.Close asChild>
                  <NavbarLink mobile href="/payments" className="ml-11">
                    Payments
                  </NavbarLink>
                </DialogPrimitive.Close>
              </div>
              <Separator className="bg-grey-tertiary" />
              <div>
                <div className="mb-2.5 flex items-center gap-x-3 px-3">
                  <UserAvatar className="shrink-0" />
                  <div className="flex flex-1 flex-col">
                    <span className="text-base font-semibold empty:hidden">{fullName}</span>
                    <span className="text-sm font-medium empty:hidden">{businessName}</span>
                  </div>
                </div>
                <DialogPrimitive.Close asChild>
                  <NavbarLink mobile href="/settings" className="ml-11">
                    Profile and settings
                  </NavbarLink>
                </DialogPrimitive.Close>
                <DialogPrimitive.Close asChild>
                  <UnstyledButton
                    onClick={handleSignOut}
                    disabled={isPending}
                    className="ml-11 block cursor-pointer px-3 py-2.5 text-left text-base font-semibold text-white"
                  >
                    Sign out
                  </UnstyledButton>
                </DialogPrimitive.Close>
              </div>
              <Separator className="bg-grey-tertiary" />
              <div>
                <div className="flex items-center gap-x-3 px-3">
                  <HelpCircleIcon className="size-8 shrink-0 p-1" />
                  <div className="flex-1 text-base font-semibold">Help</div>
                </div>
                <DialogPrimitive.Close asChild>
                  <NavbarLink mobile href="/help" className="ml-11">
                    Get support
                  </NavbarLink>
                </DialogPrimitive.Close>
              </div>
            </nav>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};
