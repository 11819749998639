'use client';

import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';

import { Avatar, type AvatarProps } from '@/components/avatar';
import { userManager } from '@/models/user';

export const UserAvatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, ...restProps }, ref) => {
    const { user } = useSnapshot(userManager);
    const fallback = user ? `${user.firstName.at(0)}${user.lastName.at(0)}` : null;

    return (
      <Avatar
        ref={ref}
        className={twMerge(
          'size-8 border-2 border-white bg-blue text-white transition-colors duration-150 ease-linear hover:bg-blue-dark',
          className,
        )}
        {...restProps}
      >
        {fallback}
      </Avatar>
    );
  },
);

UserAvatar.displayName = 'UserAvatar';
