'use client';

import { cva } from 'class-variance-authority';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

const linkVariants = cva(
  'relative inline-block cursor-pointer appearance-none whitespace-nowrap font-semibold leading-[54px] text-white/60 no-underline transition-colors duration-150 ease-linear hover:text-white focus:text-white/80',
  {
    variants: {
      active: {
        true: 'after:absolute after:inset-x-0 after:bottom-0 after:h-1 after:bg-blue after:content-[""]',
      },
    },
  },
);
const mobileLinkVariants = cva(
  'relative flex cursor-pointer appearance-none items-center gap-x-3 rounded px-3 py-2.5 font-semibold text-white no-underline transition-colors duration-150 ease-linear',
  {
    variants: {
      active: {
        true: 'bg-blue hover:bg-blue-dark active:bg-blue-dark',
      },
    },
  },
);

interface NavbarLinkProps extends Omit<React.ComponentPropsWithoutRef<typeof Link>, 'href'> {
  href?: string;
  mobile?: boolean;
}

export const NavbarLink: React.FC<NavbarLinkProps> = ({
  href,
  mobile = false,
  hidden,
  className,
  children,
  ...restProps
}) => {
  const pathname = usePathname();
  const active = pathname === href;

  if (hidden || !href) {
    return null;
  }

  if (mobile) {
    return (
      <Link
        href={href}
        className={twMerge(mobileLinkVariants({ active, className }))}
        {...restProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link href={href} className={twMerge(linkVariants({ active, className }))} {...restProps}>
      {children}
    </Link>
  );
};
