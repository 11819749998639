import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { toast } from 'sonner';

import { request } from '@/libs/request';
import { resetProducts } from '@/models/product';
import { resetUserProfile } from '@/models/user';

export const useSignOut = () => {
  const router = useRouter();

  return useMutation({
    mutationKey: ['SignOut'],
    mutationFn: async () => {
      const access_token = sessionStorage.getItem('access_token');
      if (!access_token) return;
      await request.post('/sign-out', {
        data: {
          access_token,
        },
      });
    },
    onSuccess() {
      resetProducts();
      resetUserProfile();
      sessionStorage.clear();
      router.replace('/sign-in');
      toast.success('Sign out successful.');
    },
    onError(error) {
      toast.error(error instanceof Error ? error.message : 'Failed to sign out.');
    },
  });
};
