import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { toast } from 'sonner';
import { useSnapshot } from 'valtio';

import { request } from '@/libs/request';
import {
  resetUserProfile,
  saveUserProfile,
  toggleLoading,
  type UserDetail,
  userManager,
} from '@/models/user';

type GetUserProfileResponse = {
  data: UserDetail;
} | null;

export const useGetUserProfile = (authed: boolean) => {
  const router = useRouter();
  const mountedRef = useRef(false);
  const { forceUpdate } = useSnapshot(userManager);

  const forceSignOut = (error?: unknown) => {
    resetUserProfile();
    sessionStorage.clear();
    router.replace('/sign-in');
    toast.error(error instanceof Error ? error.message : 'Failed to get user profile');
  };

  const query = useQuery({
    enabled: authed,
    retry: false,
    refetchOnWindowFocus: false,
    queryKey: ['GetUserProfile'],
    queryFn: async () => {
      const { data } = await request.get<GetUserProfileResponse>('/users/profile');

      if (data?.data) {
        saveUserProfile(data.data);
      } else {
        forceSignOut();
      }

      return data?.data ?? null;
    },
  });

  useEffect(() => {
    if (query.error) {
      forceSignOut(query.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error]);

  useEffect(() => {
    if (!mountedRef.current) return;
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  useEffect(() => {
    toggleLoading(query.isFetching);
  }, [query.isFetching]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return query;
};
