import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio/react';

import { ProductReferenceCode, ProductType } from '@/libs/enum';
import { request } from '@/libs/request';
import { type Product, productManager, saveProducts, toggleLoading } from '@/models/product';

type GetProductsResponse = {
  data: Product[];
};

export const useGetProducts = (authed: boolean) => {
  const mountedRef = useRef(false);
  const { forceUpdate } = useSnapshot(productManager);

  const query = useQuery({
    enabled: authed,
    retry: false,
    refetchOnWindowFocus: false,
    queryKey: ['GetProducts'],
    queryFn: async () => {
      // const { data } = await request.get<GetProductsResponse>('/products');
      // return data?.data ?? [];
      const products: Product[] = [
        {
          code: 'CNT0000001',
          description: 'RBN Victorian Domestic Building contract',
          id: '5e12ff57-d9d6-45f4-bf21-61b107154496',
          name: 'RBN Victorian Domestic Building contract',
          referenceCode: ProductReferenceCode.ONPLATFORM_PRIME_RBN_NEW_HOME,
          taxablePrice: {
            amount: 129.8,
            excludeGstAmount: 118,
            gstAmount: 11.8,
          },
          type: ProductType.CONTRACT,
        },
        {
          code: 'CNT0000002',
          description: 'RBN Victorian Home Improvements Building contract',
          id: '2592d3d2-fb1f-4e7e-bac0-d3bf7a3a76bd',
          name: 'RBN Victorian Home Improvements Building contract',
          referenceCode: ProductReferenceCode.ONPLATFORM_PRIME_RBN_IMPROVEMENT,
          taxablePrice: {
            amount: 129.8,
            excludeGstAmount: 118,
            gstAmount: 11.8,
          },
          type: ProductType.CONTRACT,
        },
        {
          code: 'CNT0000003',
          description: 'Paid Subcontract',
          id: '000ef15b-ad96-4d53-8c79-fd7f9ae0c790',
          name: 'Paid Subcontract',
          referenceCode: ProductReferenceCode.ONPLATFORM_SUB_PAID,
          taxablePrice: {
            amount: 10.99,
            excludeGstAmount: 9.99,
            gstAmount: 1,
          },
          type: ProductType.CONTRACT,
        },
        {
          code: 'CNT0000004',
          description: 'Paid Supply contract',
          id: 'e2134575-d61d-4321-aa74-a9d53b44b052',
          name: 'Paid Supply contract',
          referenceCode: ProductReferenceCode.ONPLATFORM_SUPPLY_PAID,
          taxablePrice: {
            amount: 10.99,
            excludeGstAmount: 9.99,
            gstAmount: 1,
          },
          type: ProductType.CONTRACT,
        },
      ];

      saveProducts(products);

      return products;
    },
  });

  useEffect(() => {
    if (!mountedRef.current) return;
    query.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  useEffect(() => {
    toggleLoading(query.isFetching);
  }, [query.isFetching]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return query;
};
