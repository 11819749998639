'use client';

import { useSnapshot } from 'valtio';

import { UnstyledButton } from '@/components/unstyled-button';
import { userManager } from '@/models/user';
import { useSignOut } from '@/mutations/sign-out';

import { DropdownMenu, type MenuItem } from '../dropdown-menu';
import { UserAvatar } from '../user-avatar';

export const UserCenter: React.FC = () => {
  const { user } = useSnapshot(userManager);
  const { mutate, isPending } = useSignOut();
  const userName = [user?.firstName, user?.lastName].filter(Boolean).join(' ');

  const menuItems: MenuItem[] = [
    {
      type: 'item',
      hoverable: false,
      title: (
        <>
          <div>
            Signed as <strong>{userName}</strong>
          </div>
          <div className="empty:hidden">{user?.email}</div>
        </>
      ),
    },
    {
      type: 'separator',
    },
    {
      type: 'item',
      title: 'Profile and settings',
      href: '/me',
    },
    {
      type: 'item',
      title: 'Sign out',
      danger: true,
      disabled: isPending,
      onSelect: () => mutate(),
    },
  ];

  return (
    <DropdownMenu disabled={!user} items={menuItems} asChild>
      <UnstyledButton className="hidden shrink-0 cursor-pointer sm:inline-block">
        <UserAvatar />
      </UnstyledButton>
    </DropdownMenu>
  );
};
