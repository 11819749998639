'use client';

import { redirect } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useGetProducts } from '@/queries/products';
import { useGetUserProfile } from '@/queries/user-profile';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authed, setAuthed] = useState(false);
  const { isLoading } = useGetUserProfile(authed);

  useGetProducts(authed);

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');

    if (token) {
      setAuthed(true);
      return;
    }

    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set('next', window.location.pathname);
    redirect(`/sign-in?${newSearchParams.toString()}`);
  }, []);

  return authed && !isLoading ? children : null;
};
